<template>
  <CRow>
    
    <CCol xs="12" md="6">
      <CInput placeholder="Ingrese el texto a buscar" v-model="query.keyword" @keyup="onTyping">
        <template #prepend-content
          ><font-awesome-icon icon="search"
        /></template>
      </CInput>
    </CCol>
    <CCol xs="6" sm="3" md="3">
      <input type="checkbox" v-model="query.withinvoice"  @change="onTyping"/> Con Factura
    </CCol>
    <CCol xs="6" sm="3" md="3">
      <input type="checkbox" v-model="query.withoutinvoice" @change="onTyping"  /> Sin Factura
    </CCol>
    <CCol xs="12" sm="3" md="3">
      <CSelect label="Status" aria-label="Status" v-model="query.status" :options="status" @change="onTyping" />
    </CCol>
    <CCol xs="12" md="3">
      <CInput label="Desde" type="date" required v-model="query.datefrom" @change="onTyping" />
    </CCol>
    <CCol xs="12" md="3">
      <CInput label="Hasta" type="date" required v-model="query.dateto" @change="onTyping" />
    </CCol>
    <CCol col="12" class="mb-2">
      <CButton
        class="float-right"
        color="success"
        @click="() => $router.push('/liquidations/0')"
        v-show="roleSeller || roleVendor"
      >
        Nueva liquidación
      </CButton>

      <CButton class="float-right mr-2" color="success" @click="exportData()">
        Exportar
      </CButton>
    </CCol>

    <CCol col="12">
      <CCard>
        <CCardHeader> Liquidaciones </CCardHeader>

        <CCardBody>
          <div class="text-center">
            <CSpinner v-if="loading" variant="grow" size="lg" />
          </div>

          <CDataTable
            v-if="!loading"
            hover
            striped
            sorter
            :items="items"
            :fields="fields"
            clickable-rows
            @onSorterValueChange="test"
            @row-clicked="rowClicked"
          >
            <template #user="data" v-if="roleVendor">
              <td>
                {{ data.item.user.name }}
              </td>
            </template>
            <template #created_at="data">
              <td>
                {{ data.item.created_at | dateFormat }}
              </td>
            </template>
            <template #status="data">
              <td>
                <CBadge :color="getBadge(data.item.status)">
                  {{ data.item.status }}
                </CBadge>
              </td>
            </template>
            <template #total="data">
              <td>
                {{ formatPrice(data.item.total) }}
              </td>
            </template>
            <template #email="data">
              <td>
                {{ data.item.user.email }}
              </td>
            </template>
            <template #userLiq="data">
              <td>
                {{ data.item.user.name }}
              </td>
            </template>
            <template #invoice="data">
              <td>
                <a
                  :href="backendEnv+'/' + data.item.pdf"
                  target="_blank"
                  v-if = "data.item.pdf != ''"
                  ><font-awesome-icon icon="download"
                /></a>
              </td>
            </template>
          </CDataTable>

          <CPagination
            v-if="!loading"
            :activePage.sync="query.page"
            :pages="lastPage"
            size="sm"
            align="center"
            @update:activePage="pageChange"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import ReportResource from "@/api/report";
import moment from "moment";

const resource = new Resource("liquidation");
const reportResource = new ReportResource();

export default {
  name: "Liquidations",
  data() {
    return {
      status: [
        '',
        'Pendiente',
        'Rechazado',
        'Pagada',
        'Aprobado',
      ],
      roleSeller: false,
      roleVendor: false,
      items: [],
      loading: false,
      fields: [
        { key: "id", label: "#", _classes: "font-weight-bold" },
        { key: "name", label: "Nombre" },
        { key: "identification", label: "CI/RUC" },
        { key: "email", label: "Correo" },
        { key: "created_at", label: "Fecha/Hora" },
        { key: "userLiq", label: "Usuario" },
        { key: "status", label: "Estado" },
        { key: "invoice", label: "Factura" },
        { key: "total", label: "Total" },
        { key: "pending", label: "Por pagar" },
      ],
      lastPage: 1,
      backendEnv: '',
      query: {
        page: 1,
        perPage: 10,
        keyword: '',
        withinvoice: true,
        withoutinvoice: true,
        status: '',
        datefrom: null,
        daeto: null,
        order: {
          created_at: null,
          email: null,
          status: null,
          invoice: null,
          identification: null,
        },
      },
    };
  },

  computed: {
    filterFields() {
      return this.roleVendor
        ? this.fields
        : this.fields.filter((item) => item.key != "user");
    },
  },

  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },

  mounted() {
    this.backendEnv = process.env.VUE_APP_BACKEND_URL;
    this.roleVendor = this.$store.state.user.role == "Mayorista";
    this.roleSeller = this.$store.state.user.role == "Distribuidor";
    this.query.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },

  methods: {
    test(event){
      console.log("Si");
      console.log(event);
    },
    async getItems() {
      this.loading = true;
      try {
        const response = await resource.list(this.query);
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getBadge(status) {
      switch (status) {
        case "Aprobado":
          return "success";
        case "Pagado":
          return "info";
        case "Pendiente":
          return "secondary";
        case "Inactivo":
          return "warning";
        case "Rechazado":
          return "danger";
        default:
          "primary";
      }
    },

    rowClicked(item, index) {
      this.$router.push({ path: `liquidations/${item.id}` });
    },

    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.query.page = val;
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    async exportData() {
      await reportResource.liquidation([]).then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "liquidaciones.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },

    onTyping() {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async () => {
        if(this.query.page != 1){
          this.$router.push({ query: { page: 1 } });
          this.query.page = 1;
        }
        await this.getItems();
      }, 500);
    },
  },
};
</script>
